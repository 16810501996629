var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isTable)?_c('v-btn',{staticClass:"mt-4",staticStyle:{"color":"white"},attrs:{"disabled":!_vm.tableChartRangeDate,"small":"","block":"","color":"#0da344"},on:{"click":function($event){return _vm.openVectorLayerTablePopup()}}},[_vm._v(" "+_vm._s(_vm.$t('Marker.tab'))+" ")]):_vm._e(),(_vm.isGraph)?_c('v-btn',{attrs:{"disabled":!_vm.tableChartRangeDate,"small":"","block":"","outlined":"","color":"#0da344"},on:{"click":function($event){return _vm.openVectorLayerGraphPopup()}}},[_vm._v(" "+_vm._s(_vm.$t('Marker.graph'))+" ")]):_vm._e(),(_vm.renderDialog)?_c('v-dialog',{attrs:{"min-width":"500px","width":"80vw","fullscreen":_vm.mobileBreakpoints()},on:{"click:outside":function($event){return _vm.resetVectorForm()}},model:{value:(_vm.openVectorLayerTableChartPopup),callback:function ($$v) {_vm.openVectorLayerTableChartPopup=$$v},expression:"openVectorLayerTableChartPopup"}},[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-card',[_c('v-card-title',{staticClass:"green--text text--accent-4 d-flex justify-space-between"},[(_vm.isVectorTable)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('Marker.tab'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('Marker.graph'))+" ")]),_c('v-icon',{attrs:{"color":"red","size":"30"},on:{"click":function($event){return _vm.resetVectorForm()}}},[_vm._v(" mdi-close ")])],1),(_vm.renderContent)?_c('v-card-text',[(!_vm.isVectorTable)?_c('div',[_c('div',{staticClass:"d-flex align-center"},[_c('v-select',{attrs:{"items":_vm.table_chart.vectorFeatureProperties.filter(function (item, index, self) { return self.indexOf(item) === index; }),"label":_vm.$t('ServiceCard.layers'),"chips":"","multiple":"","counter":"2","return-object":"","item-color":"RMprimary","color":"RMprimary"},on:{"input":_vm.limiterSelect},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t((item + "_serviceID:" + _vm.serviceCardId + ".label")))+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-2",attrs:{"small":"","color":"RMprimary","text-color":"white","close":""},on:{"click:close":function($event){_vm.chipSelectedVectorLayers.splice(_vm.chipSelectedVectorLayers.findIndex(function (chip) { return chip === item; }), 1)}}},[_c('span',[_vm._v(_vm._s(_vm.$t((item + "_serviceID:" + _vm.serviceCardId + ".label"))))])])]}}],null,false,3742750881),model:{value:(_vm.chipSelectedVectorLayers),callback:function ($$v) {_vm.chipSelectedVectorLayers=$$v},expression:"chipSelectedVectorLayers"}}),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"#0da344","small":"","outlined":""},on:{"click":function($event){return _vm.updateVectorTableChart(_vm.chipSelectedVectorLayers)}}},[_vm._v(" "+_vm._s(_vm.$t('ServiceCard.confirm'))+" ")])],1)]):_vm._e(),_c('div',[_c('div',{staticClass:"d-flex justify-space-between align-center"},[(_vm.map.distanceFromPoint.length > 0 && _vm.isTable)?_c('div',[_c('b',[_vm._v("Coordinate punto")]),_c('p',[_vm._v(" Latitudine: "+_vm._s(_vm.map.coordsLonLat[1])),_c('br'),_vm._v(" Longitudine: "+_vm._s(_vm.map.coordsLonLat[0])+" ")])]):_vm._e(),(!_vm.isTable)?_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('b',[_vm._v("Coordinate punto")]),_c('p',[_vm._v(" Latitudine: "+_vm._s(_vm.map.selectedFeatureObj.latitudine)),_c('br'),_vm._v(" Longitudine: "+_vm._s(_vm.map.selectedFeatureObj.longitudine)+" ")])]),_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('p',[_c('b',[_vm._v("Radarmeteo ID: ")]),_vm._v(_vm._s(_vm.map.selectedFeatureObj.radarmeteo_id)),_c('br'),_c('b',[_vm._v("Stazione: ")]),_vm._v(_vm._s(_vm.map.selectedFeatureObj.stazione)),_c('br'),_c('b',[_vm._v("Rete: ")]),_vm._v(_vm._s(_vm.map.selectedFeatureObj.rete)+" ")])])],1):_vm._e()],1),(_vm.isVectorTable)?_c('div',{staticClass:"d-flex flex-column"},[_c('v-menu',{staticClass:"text-center",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",staticStyle:{"margin-left":"auto"},attrs:{"color":"#4caf50","small":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('ServiceCard.export'))+" "),_c('v-icon',{staticClass:"ma-1",attrs:{"color":"#4caf50"}},[_vm._v(" mdi-table ")])],1)]}}],null,false,4040639742)},[(_vm.isVectorTable)?_c('v-list',_vm._l((_vm.exportTableExtensions),function(item){return _c('v-list-item',{key:item.type,attrs:{"color":item.color},on:{"click":function($event){return _vm.exportTable(item.type)}}},[_c('v-list-item-icon',{staticClass:"mr-1 ml-2"},[_c('v-icon',{attrs:{"color":item.color}},[_vm._v(" mdi-file-export ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{style:(("color: " + (item.color)))},[_vm._v(_vm._s(item.type))])])],1)],1)}),1):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"requests-table","fixed-header":"","height":_vm.table_chart.allVectorFeatures.length > 8 ? '450px' : '',"headers":_vm.table_chart.vectorTableHeaders,"items":_vm.table_chart.allVectorFeatures,"items-per-page":10,"mobile-breakpoint":0,"sort-by":"radarmeteo_id","footer-props":{
                itemsPerPageAllText: _vm.$t('Table.all'),
                itemsPerPageText: _vm.$t('Table.rowsPerPage'),
                pageText: ("{0}-{1} " + (_vm.$t('Table.of')) + " {2}")
              },"no-data-text":_vm.$t('Table.noData')},scopedSlots:_vm._u([_vm._l((_vm.table_chart.vectorTableHeaders),function(h){return {key:("header." + (h.value)),fn:function(ref){
              var header = ref.header;
return [_vm._v(" "+_vm._s(header.text.charAt(0) === '_' ? ("" + (_vm.$t(((header.text.replace("_", "")) + "_serviceID:" + _vm.serviceCardId + ".label"))) + (_vm.$t(((header.text.replace("_", "")) + "_serviceID:" + _vm.serviceCardId + ".udm")))) : header.text)+" ")]}}}),{key:"header.datetime_range",fn:function(ref){
              var header = ref.header;
return [_vm._v(" "+_vm._s(header = _vm.$t('ServiceCard.date'))+" ")]}},{key:"item.datetime_range",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.datetime_range.includes('/') ? item.datetime_range : _vm.formatDate(item.datetime_range))+" ")]}}],null,true)})],1):_c('div',[(_vm.renderCharts)?_c('div',[_c('div',{staticClass:"mb-2 d-flex justify-space-between align-center"},[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.mandatoryChart),callback:function ($$v) {_vm.mandatoryChart=$$v},expression:"mandatoryChart"}},[_c('v-radio',{attrs:{"label":_vm.$t('Graph.line'),"ripple":true,"value":"line","color":"RMprimary"}}),_c('v-radio',{attrs:{"label":_vm.$t('Graph.bar'),"ripple":true,"value":"bar","color":"RMprimary"}})],1),_c('v-menu',{staticClass:"text-center",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#4caf50","small":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('ServiceCard.export'))+" "),_c('v-icon',{staticClass:"ma-1",attrs:{"color":"#4caf50"}},[_vm._v(" mdi-chart-line ")])],1)]}}],null,false,1003246941)},[_c('v-list',_vm._l((_vm.exportChartExtensions),function(item){return _c('v-list-item',{key:item.type,attrs:{"color":item.color},on:{"click":function($event){return _vm.exportChart(item.type)}}},[_c('v-list-item-icon',{staticClass:"mr-1 ml-2"},[_c('v-icon',{attrs:{"color":item.color}},[_vm._v(" mdi-file-export ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{style:(("color: " + (item.color)))},[_vm._v(_vm._s(item.type))])])],1)],1)}),1)],1)],1),(_vm.mandatoryChart === 'line')?_c('line-chart',{ref:"chart",attrs:{"datasets":_vm.table_chart.rasterVectorChartDataSetLine}}):_c('bar-chart',{ref:"chart",attrs:{"datasets":_vm.table_chart.rasterVectorChartDataSetLine}})],1):_vm._e()])])]):_c('v-spacer',{staticClass:"pb-16"})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }